<template>
  <OnboardingStep
    :title="$t('onboarding.quickbooksSetupView.title')"
    :next-text="$t('onboarding.quickbooksSetupView.nextText')"
    v-bind="$props"
  >
    <QuickbooksView v-bind="$props" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import QuickbooksView from "../Settings/AutomationView/QuickbooksView";

export default {
  name: "QuickbooksSetupView",
  components: { QuickbooksView },
  extends: BaseStepView,
};
</script>
